import React, { useEffect } from 'react';
import { Col, Row, Card, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

const File1 = require('../assets/new/P09002_ATAP.pdf');
const File2 = require('../assets/new/P12001_EP.pdf');
const File3 = require('../assets/new/P09001_CSP.pdf');
const File4 = require('../assets/new/P01002_WHS.pdf');
const File5 = require('../assets/original/summary_rights_oblig_pass_a.pdf');
const File6 = require('../assets/new/P11003_RPP.pdf');
const Image1 = require('../assets/original/_DA_8568_med.jpg');

export default function Policies() {
  useEffect(() => {
    document.body.classList.add("PagePolicies");
    return () => {
      document.body.classList.remove("PagePolicies");
    };
  }, []);
  return (
    <div className="Policies PageSingle" style={{paddingBottom: "50px"}}>
      <section className="PageHero">
        <div className="AppContainer">
          <h1 className="title">Ogden's Policies</h1>
        </div>
      </section>
      <div className="AppContainer">
        <Row gutter={[20, 0]}>
          <Col xs={{span: 24, order: 2}} md={{span: 16, order: 1}}>
            <Card bordered={false} className="CardItem">
              <p>Below you will find various policies for Ogden's Coaches.</p>
              <p>If you have any questions about any of our policies or procedures, please <a href="/contact-us">contact us</a>.</p>

              <h2 id="accessible-transport-action-plan">Accessible Transport Action Plan</h2>
              <Button href={File1} className="mt-0 mb-40" icon={<DownloadOutlined />} target="_blank">Accessible Transport Action Plan</Button>
              
              <h2 id="environmental-policy">Environmental Policy</h2>
              <Button href={File2} className="mt-0 mb-40" icon={<DownloadOutlined />} target="_blank">Ogden's Environmental Policy</Button>
              
              <h2 id="customer-service-plan">Customer Service Plan</h2>
              <Button href={File3} className="mt-0 mb-40" icon={<DownloadOutlined />} target="_blank">Customer Service Plan</Button>
              
              <h2 id="work-health-and-safety-plan">Work Health & Safety Plan</h2>
              <Button href={File4} className="mt-0 mb-40" icon={<DownloadOutlined />} target="_blank">Ogden's WHS Plan</Button>
              
              <h2 id="summary-of-passengers-rights">Summary of Passenger's Rights & Obligations</h2>
              <Button href={File5} className="mt-0 mb-40" icon={<DownloadOutlined />} target="_blank">Summary of Passenger's Rights & Obligations</Button>
              
              <h2 id="revenue-protection-plan">Revenue Protection Plan</h2>
              <Button href={File6} className="mt-0 mb-40" icon={<DownloadOutlined />} target="_blank">Revenue Protection Plan</Button>
            </Card>
          </Col>
          <Col xs={{span: 24, order: 1}} md={{span: 8, order: 2}}>
            <Card bordered={false} className="CardItem Cover" bodyStyle={{padding: 0}} cover={<img alt="" src={Image1} />}></Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}
